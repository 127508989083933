import React, {useEffect, lazy, Suspense, useMemo, memo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useMediaQuery} from "react-responsive";
import {Route, Switch, Redirect, useHistory, useLocation} from "react-router-dom";
import {COMMON_ROUTES, PRIVATE_ROUTES, PUBLIC_ROUTES} from "../config/routes";
import styled, {css} from "styled-components";
import {checkToken} from "../actions/auth";
import {Loader} from "../components/Loader";
import {isPublicRoute} from "../utils/checkRoutes";
import {useQuery} from "../hooks/useQuery";
import {setAuthToken} from "../wrappers/axios";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {UserActions} from "../reducers/user";
// import {Page} from "../pages/Folio/styled";
const Page404 = lazy(() => import("../pages/Page404"));

const renderRoutes = (routes) => {
  const isMobile = window.innerWidth <= 768;

  const getIsExact = (path) => {
    if (path.includes("statistics")) {
      return isMobile;
    }
    return true;
  };

  return Object.values(routes).map(({component, path}) => (
    <Route key={path} exact={getIsExact(path)} path={path} component={component} />
  ));
};

const notCommonRoutes = Object.values({...PUBLIC_ROUTES, ...PRIVATE_ROUTES});

const checkRoute = (pathname) => {
  return notCommonRoutes.some(({path}) => {
    return (
      pathname !== "/" &&
      pathname !== "" &&
      pathname.indexOf(path.replace(/:folioId/, "").replace(/:token/, "")) !== -1
    );
  });
};

const App = memo(() => {
  const history = useHistory();
  const location = useLocation();
  const {isAuth, checkedToken} = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const isPublishedRoute = useMemo(() => !checkRoute(location.pathname), [
    location.pathname,
  ]);
  const token = useQuery().get("access_token") || localStorage.getItem("access_token");
  const userId = useQuery().get("userId") || localStorage.getItem("session_user_id");
  const redirect_from_admin = useQuery().get("redirect_from_admin");
  const redirect = useQuery().get("redirect");
  const account_id = useQuery().get("account_id");

  if (!account_id) {
    dispatch(UserActions.setAccountId(null));
  } else {
    try {
      const parsedAccountId = Number(window.atob(account_id));
      if (isNaN(parsedAccountId)) {
        dispatch(UserActions.setAccountId(null));
      } else {
        dispatch(UserActions.setAccountId(parsedAccountId));
      }
    } catch (error) {
      dispatch(UserActions.setAccountId(null));
    }
  }

  const mobile = useMediaQuery({maxWidth: 768});
  const showTutorial =
    useSelector((state) => state.user.profile.show_tutorial) &&
    !mobile &&
    !localStorage.getItem("session_user_id");

  useEffect(() => {
    const detectShiftClick = (e) => {
      if (e.shiftKey) {
        localStorage.setItem("keydown-shift-button", "1");
      }
    };
    const removeShiftClick = () => {
      localStorage.removeItem("keydown-shift-button");
    };
    document.addEventListener("keydown", detectShiftClick);
    document.addEventListener("keyup", removeShiftClick);
    return () => {
      document.removeEventListener("keydown", detectShiftClick);
      document.removeEventListener("keyup", removeShiftClick);
    };
  }, []);

  useEffect(() => {
    if (redirect_from_admin) {
      localStorage.setItem("redirect_from_admin", redirect_from_admin);
    }
  }, [redirect_from_admin]);

  useEffect(() => {
    if (userId) {
      localStorage.setItem("session_user_id", userId);
      dispatch(UserActions.setUserId(userId));
    }
    if (token) {
      setAuthToken(token);
      localStorage.setItem("access_token", token);
      if (!redirect) {
        const url = new URL(window.location.origin + window.location.pathname);
        if (account_id) {
          url.searchParams.set("account_id", account_id);
        }
        window.history.pushState({}, document.title, url);
      }
    }
    if (!isPublishedRoute) {
      dispatch(checkToken(token));
    }
  }, [account_id, dispatch, isPublishedRoute, redirect, token, userId]);

  useEffect(() => {
    if (!localStorage.getItem("refresh_token_fetching")) {
      if (!isAuth && checkedToken && !isPublishedRoute) {
        if (!isPublicRoute()) {
          history.push(
            `${PUBLIC_ROUTES.AUTH.path}?redirect=${encodeURIComponent(location.pathname)}`
          );
        }
      } else if (
        isAuth &&
        !redirect &&
        (location.pathname.indexOf(PUBLIC_ROUTES.AUTH.path) !== -1 ||
          location.pathname.indexOf(PUBLIC_ROUTES.REGISTER.path) !== -1)
      ) {
        history.push(PRIVATE_ROUTES.FOLIO.path);
      }
    }
  }, [checkedToken, history, isAuth, isPublishedRoute, location.pathname, redirect]);

  if (!checkedToken && !isPublishedRoute) return <Loader height="100vh" />;

  if (location.pathname === "/" || location.pathname === "") {
    return <Redirect to={isAuth ? PRIVATE_ROUTES.FOLIO.path : PUBLIC_ROUTES.AUTH.path} />;
  }

  return (
    <Wrapper showTutorial={showTutorial} location={location.pathname}>
      <Suspense fallback={<Loader height="100vh" />}>
        <Switch>
          {renderRoutes(PRIVATE_ROUTES)}
          {renderRoutes(PUBLIC_ROUTES)}
          {renderRoutes(COMMON_ROUTES)}
          <Route component={Page404} />
        </Switch>
      </Suspense>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        className={"toast_custom"}
      />
      <div id="toolbar-anchor" />
    </Wrapper>
  );
});

App.displayName = "App";

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  ${({showTutorial, location}) =>
    showTutorial && !location.includes("template")
      ? css`
          overflow: hidden;
        `
      : null}
`;

export default App;
